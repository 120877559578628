import React, { useState, useContext } from "react";
import styled, { withTheme } from "styled-components";

import {
    Badge,
    Grid,
    Hidden,
    Menu,
    MenuItem,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    Toolbar,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { Menu as MenuIcon } from "@material-ui/icons";

import { Bell, Settings, LogOut } from "react-feather";

import { AuthContext } from "../../util/provider/AuthProvider"

const appBarHeight = 30;

const AppBar = styled(MuiAppBar)`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
    box-shadow: ${(props) => props.theme.shadows[1]};
    min-height: ${appBarHeight}px;
    height: ${appBarHeight}px;
    padding: 0 !important;
`;

const IconButton = styled(MuiIconButton)`
    svg {
        width: 14px;
        height: 14px;
    }
`;

const Indicator = styled(Badge)`
    .MuiBadge-badge {
        background: ${(props) => props.theme.palette.primary.main};
        color: ${(props) => props.theme.palette.common.white};
    }
`;

const useStyles = makeStyles(() => ({
    toolbarStyles: {
        minHeight: appBarHeight + "px",
        height: appBarHeight + "px",
    },
    iconStyles: {
        marginRight: "25px",
    },
    powerIconStyle: {
        marginRight: "15px",
    },
}));

function UserMenu() {
    const [anchorMenu, setAnchorMenu] = useState(null);
    const classes = useStyles();
    const { handleSignOut } = useContext(AuthContext);

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const logout = () => {
        handleSignOut();
    };

    return (
        <React.Fragment>
            <IconButton
                aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                aria-haspopup="true"
                color="inherit"
                size="small"
                disableRipple
                className={classes.iconStyles}
            >
                <Settings />
            </IconButton>
            <IconButton
                aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={toggleMenu}
                color="inherit"
                size="small"
                disableRipple
                className={classes.powerIconStyle}
            >
                <LogOut />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <MenuItem onClick={closeMenu}>Profile</MenuItem>
                <MenuItem onClick={logout}>Sign out</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

function Header(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar className={classes.toolbarStyles}>
                    <Grid container alignItems="center">
                        <Hidden mdUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    size="small"
                                    disableRipple
                                    onClick={props.onDrawerToggle}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs />
                        <Grid item>
                            <IconButton
                                color="inherit"
                                size="small"
                                className={classes.iconStyles}
                                disableRipple
                            >
                                <Indicator variant="dot">
                                    <Bell />
                                </Indicator>
                            </IconButton>
                            <UserMenu />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default withTheme(Header);
