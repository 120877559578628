import * as types from "../types";

const initialState = {
    data: 
        {
          firstName: "Some First Name",
          lastName: "Some Last Name",
          phone: "+49112345678",
          email: "chr.muller@gmx.de",
          activeOrders: "1",
          completedOrders: "2",
          lastOrderDate: "13.08.20",
          registeredOn: "02.07.20",
        }
      
}

export default function CompactInfoCardReducer(state = initialState, actions) {
    switch (actions.type) {
        case types.TEST_ACTION:
            return {
                ...state,
                testProperty: actions.payload,
            };

        default:
            return state;
    }
}