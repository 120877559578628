import * as types from "../types";

export default function reducer(state = {}, actions) {
    switch (actions.type) {
        case types.TEST_ACTION:
            return {
                ...state,
                testProperty: actions.payload,
            };

        default:
            return state;
    }
}
