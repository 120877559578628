import React from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/styles";

import {
    Typography,
    Grid,
    List,
    ListItemText,
    ListItem as MuiListItem,
} from "@material-ui/core";

const Wrapper = styled.div`
    padding: 0px ${(props) => props.theme.spacing(4)}px;
    background: #1c1e24;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 28px;
`;

const ListItem = styled(MuiListItem)`
    display: inline-block;
    width: auto;
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;

    &,
    &:hover,
    &:active {
        color: #000;
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 97,
    },
    listStyles: {
        padding: 0,
        display: "flex",
        justifyContent: "flex-end",
        textAlign: "right",
        width: "100%",
    },
    listItemStyles: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    listItemTextStyles: {
        color: theme.palette.common.white,
    },
    typographyStyles: {
        color: "#eee",
    },
}));

function Footer(props) {
    const classes = useStyles(props.theme);

    return (
        <Wrapper className={classes.root}>
            <Grid container spacing={0}>
                <List className={classes.listStyles} dense>
                    <ListItem
                        className={classes.listItemStyles}
                        component="a"
                        href="#"
                    >
                        <ListItemText
                            disableTypography
                            className={classes.listItemTextStyles}
                            primary={
                                <Typography
                                    variant="caption"
                                    className={classes.typographyStyles}
                                >
                                     
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </Grid>
        </Wrapper>
    );
}

export default Footer;
