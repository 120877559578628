import React, { useState, useEffect } from "react";
import firebase from "../../firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) =>
{
    const [user, setUser] = useState();
    const [loadingAuthState, setLoadingAuthState] = useState(true);
    const db = firebase.firestore();

    useEffect(() =>
    {
        firebase.auth().onAuthStateChanged((user) =>
        {
            //console.log("Auth changed", user)
            setUser(user);
            setLoadingAuthState(true);
            if (user)
            {
                setLoadingAuthState(false);

            }
            else
            {
                setLoadingAuthState(false);
            }
        });
    }, [db]);

    const handleSignIn = (username, password) =>
    {
        db.collection("admins").where("email", "==", username)
            .get()
            .then((querySnapshot) =>
            {
                querySnapshot.forEach((doc) =>
                {
                    firebase.auth().signInWithEmailAndPassword(username, password)
                        .then((user) =>
                        {
                            return;
                        })
                        .catch((error) =>
                        {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            console.log("Found an error", errorMessage);
                        });

                });

            })
            .catch((error) =>
            {
                console.log("Error getting documents: ", error);
            });


    };

    const handleSignOut = () =>
    {
        setLoadingAuthState(true);
        firebase.auth().signOut().then(res =>
        {
            console.log("Im signed out,", res);
            setLoadingAuthState(false);
        })
            .catch(err =>
            {
                console.error(err.message);
                setLoadingAuthState(false);
            });

    };


    return (
        <AuthContext.Provider
            value={{
                user,
                authenticated: user !== null,
                setUser,
                handleSignIn,
                handleSignOut,
                loadingAuthState,
            }}>
            {children}
        </AuthContext.Provider>
    );

};