import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "components/Sidebar";
import Header from "components/Header";
import Footer from "components/Footer";

import { spacing } from "@material-ui/system";
import {
    Hidden,
    CssBaseline,
    Paper as MuiPaper,
    withWidth,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

export const drawerWidth = 230;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const Drawer = styled.div`
    ${(props) => props.theme.breakpoints.up("md")} {
        flex-shrink: 0;
    }
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 28px;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.body.background};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

const Dashboard = ({ children, routes, width }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <Drawer>
                <Hidden mdUp implementation="js">
                    <Sidebar
                        routes={routes}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    />
                </Hidden>
                <Hidden smDown implementation="css">
                    <Sidebar routes={routes} />
                </Hidden>
            </Drawer>
            <AppContent>
                <Header onDrawerToggle={handleDrawerToggle} />
                <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
                    {children}
                </MainContent>
            </AppContent>
            <Footer />
        </Root>
    );
};

export default withWidth()(Dashboard);
