import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import SignInForm from "../../pages/SignInForm";
import { AuthContext } from "../../util/provider/AuthProvider"

const SignIn = () => {
    const { authenticated, loadingAuthState, user } = useContext(AuthContext);

    if (loadingAuthState) {
        return <SignInForm  />;
    }
    return authenticated ? (
        <Redirect to={{ pathname: "/" }} />
    ) : (
        <SignInForm />
    );
};

export default SignIn;
