import * as types from "../types";

const initialState = {
    defaultSchedule: true,
    customSchedule: false,
    regionToEdit: null,
    zipToEdit: null,
    regions:  [
        {
            id: 1,
            name: "Bogenhausen",
            color: "#7598DF",
            disabled: false,
            zipCodes: [
                { 
                    id: 1,
                    value: 80797,
                    color: "#7598DF"
                },
                { 
                    id: 2,
                    value: 80795,
                    color: "#5D7092"
                },
                { 
                    id: 3,
                    value: 80796,
                    color: "#269A99"
                },
            ]
        },
        {
            id: 2,
            name: "Maxvorstadt",
            color: "#61C29B",
            disabled: false,
            zipCodes: [
                { 
                    id: 1,
                    value: 80797,
                    color: "#7598DF"
                },
            ]
        },
        {
            id: 3,
            name: "Schwabing-Nord",
            color: "#D88673",
            disabled: false,
            zipCodes: [
                { 
                    id: 1,
                    value: 80795,
                    color: "#5D7092"
                },
                { 
                    id: 2,
                    value: 80796,
                    color: "#269A99"
                },
            ]
        },
        {
            id: 4,
            name: "Schwabing-Sud",
            color: "#6DC8EC",
            disabled: false,
            zipCodes: []
        },
    ],
    selectedRegion: {
        id: -1,
        name: "Unknown",
        color: "",
        disabled: false,
    },
    defaultWeek: [
        [
            {
                id: 1,
                dayId: 1,
                regionId: 2,
                from: "10:00",
                to: "11:00",
                number: 2
            },
            {
                id: 2,
                dayId: 1,
                regionId: 4,
                from: "12:00",
                to: "14:00",
                number: 8
            },
            {
                id: 3,
                dayId: 1,
                regionId: 1,
                from: "10:00",
                to: "11:00",
                number: 2
            },
        ],
        [
            {
                id: 1,
                dayId: 2,
                regionId: 1, 
                from: "10:00",
                to: "11:00",
                number: 3
            },
            {
                id: 2,
                dayId: 2,
                regionId: 2,
                from: "10:00",
                to: "12:00",
                number: 2
            },
            {
                id: 3,
                dayId: 2,
                regionId: 1,
                from: "15:00",
                to: "16:00",
                number: 1
            }
        ],
        [
            {
                id: 1,
                dayId: 3,
                regionId: 4,
                from: "10:00",
                to: "11:00",
                number: 8
            },
        ],
        [
            {
                id: 1,
                dayId: 4,
                regionId: 1, 
                from: "10:00",
                to: "12:00",
                number: 6
            },
            {
                id: 2,
                dayId: 4,
                regionId: 3,
                from: "15:00",
                to: "16:00",
                number: 3
            }
        ],
        [
            {
                id: 1,
                dayId: 5,
                regionId: 1,
                from: "10:00",
                to: "12:00",
                number: 1
            },
            {
                id: 2,
                dayId: 5,
                regionId: 1,
                from: "15:00",
                to: "16:00",
                number: 2
            }
        ],
        [
            {
                id: 1,
                dayId: 6,
                regionId: 4,
                from: "10:00",
                to: "11:00",
                number: 2
            },
            {
                id: 2,
                dayId: 6,
                regionId: 4,
                from: "10:00",
                to: "12:00",
                number: 2
            },
        ],
        [
            {
                id: 1,
                dayId: 7,
                regionId: 1,
                from: "10:00",
                to: "11:00",
                number: 2
            },
        ]
    ],
    weeks: [
        { 
            id: 1,
            title: "Week of 4 - 10 Oct. 2020",
            startDate: "2020-10-04",
            customSchedule: true,
            days: [
                { 
                    id: 1, 
                    title: "Sun", 
                    date: "2020-10-04",
                    timeline: [
                        {
                            id: 1,
                            regionId: 2,
                            date: "2020-10-04",
                            from: "10:00",
                            to: "11:00",
                            number: 2
                        },
                    ]
                },
                { 
                    id: 2, 
                    title: "Mon",
                    date: "2020-10-05",
                    timeline: [
                        {
                            id: 1,
                            regionId: 1, 
                            date: "2020-10-05",
                            from: "10:00",
                            to: "11:00",
                            number: 3
                        },
                        {
                            id: 2,
                            regionId: 2,
                            date: "2020-10-05",
                            from: "10:00",
                            to: "12:00",
                            number: 2
                        },
                        {
                            id: 3,
                            regionId: 1,
                            date: "2020-10-05",
                            from: "15:00",
                            to: "16:00",
                            number: 1
                        }
                    ]
                },
                { 
                    id: 3, 
                    title: "Tue", 
                    date: "2020-10-06",
                    timeline: [
                        {
                            id: 1,
                            regionId: 4,
                            date: "2020-10-06",
                            from: "10:00",
                            to: "11:00",
                            number: 8
                        },
                    ]
                },
                { 
                    id: 4, 
                    title: "Wed",
                    date: "2020-10-07",
                    timeline: [
                        {
                            id: 1,
                            regionId: 1, 
                            date: "2020-10-07",
                            from: "10:00",
                            to: "12:00",
                            number: 6
                        },
                        {
                            id: 2,
                            regionId: 3,
                            date: "2020-10-07",
                            from: "15:00",
                            to: "16:00",
                            number: 3
                        }
                    ]
                },
                { 
                    id: 5, 
                    title: "Thu", 
                    date: "2020-10-08",
                    timeline: [
                        {
                            id: 1,
                            regionId: 1,
                            date: "2020-10-08",
                            from: "10:00",
                            to: "12:00",
                            number: 1
                        },
                        {
                            id: 2,
                            regionId: 1,
                            date: "2020-10-08",
                            from: "15:00",
                            to: "16:00",
                            number: 2
                        }
                    ]
                },
                { 
                    id: 6, 
                    title: "Fri", 
                    date: "2020-10-09",
                    timeline: [
                        {
                            id: 1,
                            regionId: 4,
                            date: "2020-10-09",
                            from: "10:00",
                            to: "11:00",
                            number: 2
                        },
                        {
                            id: 2,
                            regionId: 4,
                            date: "2020-10-09",
                            from: "10:00",
                            to: "12:00",
                            number: 2
                        },
                    ]
                },
                { 
                    id: 7, 
                    title: "Sat",
                    date: "2020-10-10", 
                    timeline: [
                        {
                            id: 1,
                            regionId: 1,
                            date: "2020-10-10",
                            from: "10:00",
                            to: "11:00",
                            number: 2
                        },
                    ]
                },
            ]
        },
        { 
            id: 2,
            title: "Week of 11 - 17 Oct. 2020",
            startDate: "2020-10-11",
            customSchedule: true,
            days: [
                { 
                    id: 1, 
                    title: "Sun", 
                    date: "2020-10-11",
                    timeline: [
                        {
                            id: 1,
                            regionId: 2,
                            date: "2020-10-11",
                            from: "10:00",
                            to: "11:00",
                            number: 2
                        },
                    ]
                },
                { 
                    id: 2, 
                    title: "Mon",
                    date: "2020-10-12", 
                    timeline: []
                },
                { 
                    id: 3, 
                    title: "Tue", 
                    date: "2020-10-13",
                    timeline: [
                        {
                            id: 1,
                            regionId: 3,
                            date: "2020-10-13",
                            from: "10:00",
                            to: "11:00",
                            number: 2
                        },
                    ]
                },
                { 
                    id: 4, 
                    title: "Wed", 
                    date: "2020-10-14",
                    timeline: [
                        {
                            id: 1,
                            regionId: 3,
                            date: "2020-10-14", 
                            from: "10:00",
                            to: "12:00",
                            number: 2
                        },
                    ]
                },
                { 
                    id: 5, 
                    title: "Thu", 
                    date: "2020-10-15",
                    timeline: [
                        {
                            id: 1,
                            regionId: 4,
                            date: "2020-10-15",
                            from: "10:00",
                            to: "12:00",
                            number: 2
                        },
                        {
                            id: 2,
                            regionId: 1,
                            date: "2020-10-15",
                            from: "15:00",
                            to: "16:00",
                            number: 2
                        }
                    ]
                },
                { 
                    id: 6, 
                    title: "Fri", 
                    date: "2020-10-16",
                    timeline: [
                        {
                            id: 1,
                            regionId: 1,
                            date: "2020-10-16",
                            from: "10:00",
                            to: "11:00",
                            number: 2
                        },
                    ]
                },
                { 
                    id: 7, 
                    title: "Sat", 
                    date: "2020-10-17",
                    timeline: []
                },
            ]
        },
        { 
            id: 3,
            title: "Week of 18 - 24 Oct. 2020",
            startDate:"2020-10-18",
            customSchedule: false,
            days: [
                { 
                    id: 1, 
                    title: "Sun",
                    date: "2020-10-18", 
                    timeline: []
                },
                { 
                    id: 2, 
                    title: "Mon",
                    date: "2020-10-19", 
                    timeline: [
                    ]
                },
                { 
                    id: 3, 
                    title: "Tue", 
                    date: "2020-10-20",
                    timeline: [
                    ]
                },
                { 
                    id: 4, 
                    title: "Wed", 
                    date: "2020-10-21",
                    timeline: [
                    ]
                },
                { 
                    id: 5, 
                    title: "Thu", 
                    date: "2020-10-22",
                    timeline: [
                    ]
                },
                { 
                    id: 6, 
                    title: "Fri", 
                    date: "2020-10-23",
                    timeline: [
                    ]
                },
                { 
                    id: 7, 
                    title: "Sat", 
                    date: "2020-10-24",
                    timeline: [
                    ]
                },
            ]
        },
    ],
    selectedWeek: -1,
    timelineToEdit: null,
    defaultTimelineToEdit: null,
    dayToEditId: -1,
    openExportTasksForm: false,
    data: [],
    regionsData: [],
};

export default function (state= initialState, action) {
    switch (action.type) {
        case types.SHOW_DEFAULT_SCHEDULE:
            var custom = false;
            if (!action.payload) {
                custom = state.weeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].customSchedule
            }
            return {
                ...state,
                defaultSchedule: action.payload,
                customSchedule: custom
            };
        case types.SHOW_CUSTOM_SCHEDULE:
            var newWeeks = state.weeks.map(w => ({...w, days: w.days.map(day =>({...day, timeline: day.timeline}))}))
            if (newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].customSchedule) {
                newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].days 
                = newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].days.map(day => ({...day, timeline: []}))
            } else {
                newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].days 
                = newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].days.map(day => ({...day, timeline: state.defaultWeek[day.id-1].map(t => ({date: day.date, ...t}))}))
            }
            newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].customSchedule = !newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].customSchedule
            return {
                ...state,
                weeks: newWeeks,
                customSchedule: newWeeks[state.weeks.findIndex(w => w.id === state.selectedWeek)].customSchedule
            }
        case types.FETCH_REGIONS:
            return {
                ...state
            }
        case types.SHOW_REGION_DETAILS:
            return {
                ...state, 
                selectedRegion: state.regions.filter(r => r.id === action.payload)[0]
            }
        case types.SET_REGION_DISABLED:
            let prevRegions = [...state.regions]
            const regionIndex = state.regions.findIndex(r => r.id === action.payload)
            prevRegions[regionIndex] = {...prevRegions[regionIndex], disabled: !prevRegions[regionIndex].disabled}
            return {
                ...state,
                regions: prevRegions
            }
        case types.ADD_REGION:
            const newID = state.regions[state.regions.length - 1].id + 1
            let newRegion = {
                ...action.payload,
                id: newID,
                disabled: false,
                zipCodes: []
            }
            const newRegions = [...state.regions, newRegion]
            return {
                ...state,
                regions: newRegions
            }
        case types.EDIT_REGION:
            let unEdittedRegions = [...state.regions]
            const regIndex = state.regions.findIndex(r => r.id === action.payload.id)
            const selectedRegId = state.regions.findIndex(r => r.id === state.selectedRegion.id)
            unEdittedRegions[regIndex] = {...unEdittedRegions[regIndex], ...action.payload}
            return {
                ...state,
                regions: unEdittedRegions,
                selectedRegion: unEdittedRegions[selectedRegId]
            }
        case types.GET_REGION:
            const regEdit = state.regions.filter(r => r.id === action.payload)[0]
            return {
                ...state,
                regionToEdit: regEdit
            }
        case types.DELETE_REGION:
            var oldRegions = [...state.regions]
            oldRegions = oldRegions.filter(r => r.id !== action.payload)
            var oW = state.weeks.map(w => ({...w, days: w.days.map(day =>({...day, timeline: day.timeline.filter(t => t.regionId !== action.payload)}))}))
            var newDefa = state.defaultWeek.map(d => [...d.filter(dt => dt.regionId !== action.payload)])
            return {
                ...state,
                regions: oldRegions,
                defaultWeek: newDefa,
                selectedRegion: {
                    id: -1,
                    name: "Unknown",
                    color: "",
                    disabled: false,
                },
                weeks: oW
            }
        case types.ADD_ZIP_CODE:
            let colors = ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8'] 
            const index = state.regions.findIndex(r => r.id === action.payload.id)
            var oldZipCodes = [...state.regions[index].zipCodes]
            const newZipID = oldZipCodes.length > 0 ? oldZipCodes[oldZipCodes.length - 1].id + 1 : 1
            let regs = state.regions.map(r => ({...r, zipCodes: r.zipCodes}))
            let newZipCodes = [...oldZipCodes, {
                id: newZipID,
                value: action.payload.value,
                color: colors[Math.floor(Math.random()*colors.length)]
            }]
            regs[index].zipCodes = [...newZipCodes]
            return {
                ...state,
                regions: regs,
                selectedRegion: regs[index]
            }
        case types.EDIT_ZIP_CODE:
            var reIndex = state.regions.findIndex(r => r.id === action.payload.regionId)
            oldZipCodes = [...state.regions[reIndex].zipCodes]
            var newRegs = state.regions.map(r => ({...r, zipCodes: r.zipCodes}))
            var zIndex = oldZipCodes.findIndex(z => z.id === action.payload.zipId)
            oldZipCodes[zIndex] = {...oldZipCodes[zIndex], value: action.payload.value}
            newRegs[reIndex].zipCodes = [...oldZipCodes]
            return {
                ...state,
                regions: newRegs,
                selectedRegion: newRegs[reIndex]
            }
        case types.DELETE_ZIP_CODE:
            reIndex = state.regions.findIndex(r => r.id === action.payload.regionID)
            oldZipCodes = [...state.regions[reIndex].zipCodes]
            var oldRegs = state.regions.map(r => ({...r, zipCodes: r.zipCodes}))
            oldZipCodes = oldZipCodes.filter(z => z.id !== action.payload.zipId)
            oldRegs[reIndex].zipCodes = [...oldZipCodes]
            return {
                ...state,
                regions: oldRegs,
                selectedRegion: oldRegs[reIndex]
            }
        case types.GET_ZIP_CODE:
            if(action.payload.regionID !== -1) {
                reIndex = state.regions.findIndex(r => r.id === action.payload.regionID)
                oldZipCodes = [...state.regions[reIndex].zipCodes]
                const zipEdit = oldZipCodes.filter(r => r.id === action.payload.zipId)[0]
                return {
                    ...state,
                    zipToEdit: zipEdit,
                }
            }
            return {
                ...state,
                zipToEdit: null
            }
        case types.GET_WEEKS:
            return {
                ...state,
            }
        case types.SET_WEEK:
            return {
                ...state,
                selectedWeek: action.payload,
                customSchedule: state.weeks[state.weeks.findIndex(w => w.id === action.payload)].customSchedule
            }
        case types.ADD_TIMELINE:
            var wIndex = state.weeks.findIndex(w => w.id === action.payload.weekId)
            var oldTimelines = [...state.weeks[wIndex].days[action.payload.dayId-1].timeline]
            var newTimelineID = oldTimelines.length > 0 ? oldTimelines[oldTimelines.length - 1].id + 1 : 1
            var oldWeeks = state.weeks.map(w => ({...w, days: w.days.map(day =>({...day, timeline: day.timeline}))}))
            var newTimelines = [...oldTimelines, {
                id: newTimelineID,
                ...action.payload.timeline,
            }]
            oldWeeks[wIndex].days[action.payload.dayId-1].timeline = [...newTimelines]
            return {
                ...state,
                weeks: oldWeeks,
                selectedWeek: action.payload.weekId,
            }
        case types.EDIT_TIMELINE:
            wIndex = state.weeks.findIndex(w => w.id === action.payload.weekId)
            oldTimelines = [...state.weeks[wIndex].days[action.payload.dayId-1].timeline]
            oldWeeks = state.weeks.map(w => ({...w, days: w.days.map(day =>({...day, timeline: day.timeline}))}))
            oldTimelines[action.payload.timeline.id-1] = action.payload.timeline
            newTimelines = [...oldTimelines]
            oldWeeks[wIndex].days[action.payload.dayId-1].timeline = [...newTimelines]
            return {
                ...state,
                weeks: oldWeeks,
                selectedWeek: action.payload.weekId,
            }
        case types.DELETE_TIMELINE:
            wIndex = state.weeks.findIndex(w => w.id === action.payload.weekId)
            oldTimelines = [...state.weeks[wIndex].days[action.payload.dayId-1].timeline]
            oldWeeks = state.weeks.map(w => ({...w, days: w.days.map(day =>({...day, timeline: day.timeline}))}))
            newTimelines = oldTimelines.filter(o => o.id !== action.payload.timelineId)
            oldWeeks[wIndex].days[action.payload.dayId-1].timeline = [...newTimelines]
            return {
                ...state,
                weeks: oldWeeks,
                selectedWeek: action.payload.weekId,
            }
        case types.GET_TIMELINE:
            if(action.payload.weekId !== -1) {
                var weekIndex = state.weeks.findIndex(w => w.id === action.payload.weekId)
                var oldTimeline = [...state.weeks[weekIndex].days[action.payload.dayId-1].timeline]
                var timelineEdit = oldTimeline.filter(t => t.id === action.payload.timelineId)
                return {
                    ...state,
                    timelineToEdit: timelineEdit[0],
                    dayToEditId: action.payload.dayId
                }
            }
            return {
                ...state,
                timelineToEdit: null,
            }
        case types.GET_DEFAULT_TIMELINE: {
            var newDef = state.defaultWeek[action.payload.dayId-1].filter(t => t.id === action.payload.timelineId)[0]
            console.log("red", newDef)
            return {
                ...state,
                defaultTimelineToEdit: newDef,
            }
        }
        case types.ADD_DEFAULT_TIMELINE: {
            var newId = state.defaultWeek[action.payload.dayId-1][state.defaultWeek[action.payload.dayId-1].length - 1].id + 1
            var newTim = state.defaultWeek.map(d => [...d])
            newTim[action.payload.dayId-1].push({id: newId, dayId: action.payload.dayId, regionId: action.payload.timeline.regionId, from: action.payload.timeline.from, to: action.payload.timeline.to, number: action.payload.timeline.number})
            return {
                ...state,
                defaultWeek: newTim
            }
        }
        case types.EDIT_DEFAULT_TIMELINE: {
            newTim = state.defaultWeek.map(d=>[...d])
            newTim[action.payload.dayId-1][newTim[action.payload.dayId-1].findIndex(t => t.id === action.payload.timeline.id)] = {...action.payload.timeline}
            return {
                ...state,
                defaultWeek: newTim
            }
        }
        case types.DELETE_DEFAULT_TIMELINE: {
            var newDef = state.defaultWeek[action.payload.dayId-1].filter(t => t.id !== action.payload.timelineId)
            newTim = state.defaultWeek.map(t =>[...t])
            newTim[action.payload.dayId-1] = newDef
            return {
                ...state,
                defaultWeek: newTim
            }
        }
        case types.SET_DAY_TO_EDIT:
            return {
                ...state,
                dayToEditId: action.payload
            }
        case types.OPEN_EXPORT_LOGISTICS_TASK_FORM:
            return {
                ...state,
                openExportTasksForm: action.payload,
            };
        case types.FETCH_LOGISTICS_TASK_LIST:
            return {
                ...state,
                data: action.payload.data,
                regionsData: action.payload.regions
            };
        default:
            return state;
    }
}
