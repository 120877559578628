import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "pages/App";

import { Provider } from "react-redux";
import store from "redux/store";
import { AuthProvider } from "util/provider/AuthProvider";

import "res/index.css";
import { TourProivder } from "./hooks/toursContext";
import { DiscountsProvider } from "./hooks/discountsContext";

require("dotenv").config();

ReactDOM.render(
    <AuthProvider>
        <TourProivder>
            <DiscountsProvider>
                <Provider store={store}>
                    <App/>
                </Provider>
            </DiscountsProvider>
        </TourProivder>
    </AuthProvider>,
    document.getElementById("root"),
);
