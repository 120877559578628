import firebase from 'firebase'
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore"


 export const firebaseConfig = {
      apiKey: "AIzaSyAspI6QIhd1v43vNR8V0wpJW4dL6pey0TQ",
      authDomain: "droov-fbaef.firebaseapp.com",
      databaseURL: "https://droov-fbaef.firebaseio.com",
      projectId: "droov-fbaef",
      storageBucket: "droov-fbaef.appspot.com",
      messagingSenderId: "93078866564",
      appId: "1:93078866564:web:bf2a67542dd66102ded0d1",
      measurementId: "G-JMRRRNNM3J",
      functionsURL: "https://us-central1-droov-fbaef.cloudfunctions.net"
  };


/*
export const firebaseConfig = {
    apiKey: "AIzaSyCp7sNqTBD3KOWBfha27rqScrD0dIR-QWs",
    authDomain: "droov-test.firebaseapp.com",
    projectId: "droov-test",
    storageBucket: "droov-test.appspot.com",
    messagingSenderId: "471564007260",
    appId: "1:471564007260:web:894e1e5b660a220d40b399"
};
*/



// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

const storage = firebase.storage();

export { storage, firebase as default };
