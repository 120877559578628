import { OPEN_DRAWER, CLOSE_DRAWER } from "../types";

const initialState = {
    open: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OPEN_DRAWER:
            return {
                ...state,
                open: action.payload,
            };
        case CLOSE_DRAWER:
            return {
                ...state,
                open: action.payload,
            };
        default:
            return state;
    }
}
