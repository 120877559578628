import {
    CURRENT_CUSTOMER,
    NEW_CUSTOMER_FORM,
    EDIT_CUSTOMER_FORM,
    FETCH_CUSTOMER_LIST,
    RELOAD_CUSTOMER_LIST,
} from "../types";

const initialState = {
    openNewCustomerForm: false,
    openEditCustomerForm: false,
    reloadCustomerList: false,
    curCustomer: {},
    data: {},
};

export default function customerReducer(state = initialState, action) {
    switch (action.type) {
        case CURRENT_CUSTOMER:
            return {
                ...state,
                curCustomer: action.payload,
            };
        case EDIT_CUSTOMER_FORM:
            return {
                ...state,
                openEditCustomerForm: action.payload,
            };
        case NEW_CUSTOMER_FORM:
            return {
                ...state,
                openNewCustomerForm: action.payload,
            };
        case RELOAD_CUSTOMER_LIST:
            console.log("I am reloading a customer list, " , action.payload)
            return {
                ...state,
                reloadCustomerList: action.payload,
            };
        case FETCH_CUSTOMER_LIST:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
}
