import React, { useState } from "react";
import clsx from "clsx";

import styled from "styled-components";
import { rgba } from "polished";

import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";

import { spacing } from "@material-ui/system";

import {
    Avatar,
    Badge,
    Box as MuiBox,
    Chip,
    Collapse,
    Drawer as MuiDrawer,
    Grid,
    List as MuiList,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import PerfectScrollbar from "react-perfect-scrollbar";
import "res/perfect-scrollbar.css";

import { sidebarRoutes as routes } from "util/routes/index";

import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import { useDispatch, useSelector } from "react-redux";

import { openDrawer, closeDrawer } from "../../redux/actions/drawerActions";

import { makeStyles } from "@material-ui/core/styles";

import { drawerWidth } from "../DashboardLayout/DashboardLayout";

const animationDuration = 50;

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }
`;

const List = styled(MuiList)`
    background-color: ${(props) => props.theme.sidebar.background};
    width: 100%;
    flex: auto;
`;

const Items = styled.div`
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.header.background};
    font-family: ${(props) => props.theme.typography.fontFamily};
    min-height: 56px;
    padding-left: ${(props) => props.theme.spacing(6)}px;
    padding-right: ${(props) => props.theme.spacing(6)}px;
    cursor: default;

    ${(props) => props.theme.breakpoints.up("sm")} {
        min-height: 56px;
    }

    &:hover {
        background-color: ${(props) => props.theme.sidebar.header.background};
    }
`;

const Category = styled(ListItem)`
    padding-top: ${(props) => props.theme.spacing(3)}px;
    padding-bottom: ${(props) => props.theme.spacing(3)}px;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};

    svg {
        color: ${(props) => props.theme.sidebar.color};
        font-size: 20px;
        width: 20px;
        height: 20px;
        opacity: 0.5;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.08);
    }

    &.${(props) => props.activeClassName} {
        background-color: ${(props) =>
            darken(0.05, props.theme.sidebar.background)};

        span {
            color: ${(props) => props.theme.sidebar.color};
        }
    }
`;

const CategoryText = styled(ListItemText)`
    margin: 0;
    span {
        color: ${(props) => props.theme.sidebar.color};
        font-size: ${(props) => props.theme.typography.body1.fontSize}px;
        font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
        padding: 0 ${(props) => props.theme.spacing(4)}px;
        height: 20px;
    }
`;

const CategoryIconLess = styled(ExpandLess)`
    color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
    color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-top: ${(props) => props.theme.spacing(2)}px;
    padding-bottom: ${(props) => props.theme.spacing(2)}px;

    span {
        color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
    }

    &:hover span {
        color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
    }

    &.${(props) => props.activeClassName} {
        background-color: ${(props) =>
            darken(0.06, props.theme.sidebar.background)};

        span {
            color: ${(props) => props.theme.sidebar.color};
        }
    }
`;

const LinkText = styled(ListItemText)`
    color: ${(props) => props.theme.sidebar.color};
    span {
        font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    }
    margin-top: 0;
    margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
    font-size: 11px;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    height: 20px;
    position: absolute;
    right: 12px;
    top: 8px;
    background: ${(props) => props.theme.sidebar.badge.background};

    span.MuiChip-label,
    span.MuiChip-label:hover {
        cursor: pointer;
        color: ${(props) => props.theme.sidebar.badge.color};
        padding-left: ${(props) => props.theme.spacing(2)}px;
        padding-right: ${(props) => props.theme.spacing(2)}px;
    }
`;

const CategoryBadge = styled(LinkBadge)`
    top: 12px;
`;

const Scrollbar = styled(PerfectScrollbar)`
    background-color: ${(props) => props.theme.sidebar.background};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarSection = styled(Typography)`
    color: ${(props) => props.theme.sidebar.color};
    padding-top: 12px;
    padding-bottom: 12px;
`;

const SidebarFooter = styled.div`
    background-color: ${(props) =>
        props.theme.sidebar.footer.background} !important;
    padding: ${(props) => props.theme.spacing(2.75)}px
        ${(props) => props.theme.spacing(4)}px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
    color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
    color: ${(props) => props.theme.sidebar.footer.color};
    font-size: 0.725rem;
    padding: 1px;
`;

const StyledBadge = styled(Badge)`
    span {
        background-color: ${(props) =>
            props.theme.sidebar.footer.online.background};
        border: 1.5px solid ${(props) => props.theme.palette.common.white};
        height: 12px;
        width: 12px;
        border-radius: 50%;
    }
`;

const useStyles = makeStyles((theme) => ({
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: animationDuration + "ms",
        }),
        overflowX: "hidden",
        zIndex: 98,
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: animationDuration + "ms",
        }),
        overflowX: "hidden",
        width: theme.spacing(11) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(13) + 1,
        },
        alignItems: "center",
        backgroundColor: theme.sidebar.footer.background,
        zIndex: 98,
    },
    smallLogo: {
        width: "30px",
        height: "30px",
        marginLeft: "-2px",
    },
    smalllogoContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
    smallLogoBox: {
        height: "30px",
    },
    longHeader: {
        opacity: 1,
        paddingLeft: "16px",
    },
    shortHeader: {
        borderTop: "0.5px solid #EEEEEE",
        fontWeight: 700,
        opacity: 0.5,
        paddingLeft: "16px",
        paddingRight: "16px",
        textAlign: "center",
        fontSize: "18px",
    },
}));

function SidebarCategory({
    name,
    icon,
    isOpen,
    isCollapsable,
    badge,
    ...rest
}) {
    const classes = useStyles();
    const drawerState = useSelector((state) => state.drawerReducer);
    return (
        <Category {...rest}>
            {icon}
            <CategoryText className={!drawerState.open ? classes.hide : ""}>
                {name}
            </CategoryText>
            {isCollapsable ? (
                isOpen ? (
                    <CategoryIconMore />
                ) : (
                    <CategoryIconLess />
                )
            ) : null}
            {badge ? <CategoryBadge label={badge} /> : ""}
        </Category>
    );
}

function SidebarLink({ name, to, badge }) {
    return (
        <Link
            button
            dense
            component={NavLink}
            exact
            to={to}
            activeClassName="active"
        >
            <LinkText>{name}</LinkText>
            {badge ? <LinkBadge label={badge} /> : ""}
        </Link>
    );
}

function Sidebar({ staticContext, location, ...rest }) {
    const classes = useStyles();

    const drawerState = useSelector((state) => state.drawerReducer);

    const initOpenRoutes = () => {
        /* Open collapse element that matches current url */
        const pathName = location.pathname;

        let _routes = {};

        routes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome =
                route.containsHome && pathName === "/" ? true : false;

            _routes = Object.assign({}, _routes, {
                [index]: isActive || isOpen || isHome,
            });
        });

        return _routes;
    };

    const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
    const dispatch = useDispatch();

    // Used to make sure we show user name only once the drawer is fully open.
    const [showUser, setShowUser] = useState(true);

    const toggleMenu = () => {
        if (drawerState.open) {
            dispatch(closeDrawer());
            setTimeout(() => {
                setShowUser(false);
            }, 200);
        } else {
            setShowUser(false);
            dispatch(openDrawer());
            setTimeout(() => {
                setShowUser(true);
            }, 200);
        }
    };

    const toggle = (index) => {
        // Collapse all elements
        Object.keys(openRoutes).forEach(
            (item) =>
                openRoutes[index] ||
                setOpenRoutes((openRoutes) =>
                    Object.assign({}, openRoutes, { [item]: false })
                )
        );

        // Toggle selected element
        setOpenRoutes((openRoutes) =>
            Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
        );
    };

    return (
        <Drawer
            variant="permanent"
            {...rest}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerState.open,
                [classes.drawerClose]: !drawerState.open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: drawerState.open,
                    [classes.drawerClose]: !drawerState.open,
                }),
            }}
        >
            {drawerState.open ? (
                <Brand>
                    <img src="/img/logo.svg" alt="droov.io logo" />
                    <Box ml={1}></Box>{" "}
                </Brand>
            ) : (
                <Brand className={classes.smalllogoContainer}>
                    <Box className={classes.smallLogoBox}>
                        <img
                            src="/img/sidebar_icon.svg"
                            alt="droov.io small logo"
                            className={classes.smallLogo}
                        />
                    </Box>
                </Brand>
            )}
            <Scrollbar>
                <List disablePadding>
                    <Items>
                        <ListItem button onClick={toggleMenu}>
                            <SidebarSection
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <MenuOpenIcon />
                                <span
                                    style={{ paddingLeft: 14 + "px" }}
                                    className={
                                        !drawerState.open ? classes.hide : ""
                                    }
                                >
                                    Hide menu
                                </span>
                            </SidebarSection>
                            <SidebarSection />
                        </ListItem>

                        {routes.map((category, index) => (
                            <React.Fragment key={index}>
                                {category.header && drawerState.open ? (
                                    <SidebarSection
                                        className={classes.longHeader}
                                    >
                                        {category.header}
                                    </SidebarSection>
                                ) : null}

                                {category.header && !drawerState.open ? (
                                    <SidebarSection
                                        className={classes.shortHeader}
                                    >
                                        {category.header.slice(0, 1)}
                                    </SidebarSection>
                                ) : null}

                                {category.children ? (
                                    <React.Fragment key={index}>
                                        <SidebarCategory
                                            isOpen={!openRoutes[index]}
                                            isCollapsable={true}
                                            name={category.id}
                                            icon={category.icon}
                                            button={true}
                                            onClick={() => toggle(index)}
                                        />

                                        <Collapse
                                            in={openRoutes[index]}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            {category.children.map(
                                                (route, index) => (
                                                    <SidebarLink
                                                        key={index}
                                                        name={route.name}
                                                        to={route.path}
                                                        icon={route.icon}
                                                        badge={route.badge}
                                                    />
                                                )
                                            )}
                                        </Collapse>
                                    </React.Fragment>
                                ) : (
                                    <SidebarCategory
                                        isCollapsable={false}
                                        name={category.id}
                                        to={category.path}
                                        activeClassName="active"
                                        component={NavLink}
                                        icon={category.icon}
                                        exact
                                        badge={category.badge}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </Items>
                </List>
            </Scrollbar>
            <SidebarFooter>
                <Grid container spacing={2}>
                    <Grid item>
                        <StyledBadge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                        >
                            <Avatar
                                alt="client-avatar"
                                src="img/baier-image.jpg"
                            />
                        </StyledBadge>
                    </Grid>
                    <Grid
                        item
                        className={
                            !drawerState.open ||
                            showUser === undefined ||
                            showUser === false
                                ? classes.hide
                                : ""
                        }
                    >
                        <SidebarFooterText variant="body2">
                            Korbinian Baier
                        </SidebarFooterText>
                        <SidebarFooterSubText variant="caption">
                            Kingsgard
                        </SidebarFooterSubText>
                    </Grid>
                </Grid>
            </SidebarFooter>
        </Drawer>
    );
}

export default withRouter(Sidebar);
