import React, { useState, createContext } from "react"

export const DiscountsContext = createContext();

export const DiscountsProvider = props => {
  const [discounts, setDiscounts] = useState([]);
  const [currDiscount, setCurrDiscount] = useState({})

  return (
    <DiscountsContext.Provider value={[discounts, setDiscounts, currDiscount, setCurrDiscount]}>
      {props.children}
    </DiscountsContext.Provider>
  );
}