import * as actions from "../types";

const initialState = {
    couriers: [],
    newCourierID: "",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_COURIERS:
            return {
                ...state,
                couriers: action.payload,
            };
        case actions.ADD_COURIER:
            return {
                ...state,
                newCourierID: action.payload,
            };
        case actions.EDIT_COURIER:
            return {
                ...state,
            };
        case actions.DELETE_COURIER:
            return {
                ...state,
            };
        default:
            return state;
    }
}
