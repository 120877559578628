import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { layoutRoutes } from "./index";
import { AuthContext } from "../../util/provider/AuthProvider"

import DashboardLayout from "components/DashboardLayout";
import SignIn from "./SignIn";

const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={(props) => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
                // Route item without children
                <Route
                    key={index}
                    path={path}
                    exact
                    render={(props) => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            )
    );

const Routes = () => {
    const { authenticated, loadingAuthState } = useContext(AuthContext);

    if (loadingAuthState) {
        return (
            <div className="center__all">
                <h1>Loading</h1>
            </div>
        );
    }


    return (
        <Switch>
            {!authenticated ? (
                <div>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            <SignIn />
                        )}
                    />
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                </div>

            )
                :
                childRoutes(DashboardLayout, layoutRoutes)

            }

        </Switch>
    );
};

export default Routes;
