import {
    TOGGLE_ADD_ORDER_FORM,
    FETCH_ORDERS,
    CURRENT_ORDER,
    RELOAD_ORDER_LIST,
} from "../types";

const initialState = {
    openAddOrderForm: false,
    reloadOrderList: false,
    curOrder: {},
};

export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORDERS:
            return {
                ...state,
                items: action.payload,
            };
        case CURRENT_ORDER:
            console.log(action.payload)
            return {
                ...state,
                currentOrder: action.payload,
            };
        case TOGGLE_ADD_ORDER_FORM:
            return {
                ...state,
                openAddOrderForm: action.payload,
            };
        case RELOAD_ORDER_LIST:
            return {
                ...state,
                reloadOrderList: action.payload,
            };
        default:
            return state;
    }
}
