import React, { useContext, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Helmet from "react-helmet";

import {
    Avatar,
    FormControl,
    Input,
    InputLabel,
    Button as MuiButton,
    Paper,
    Typography,
    Link,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AuthContext } from "../../util/provider/AuthProvider"


const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
    padding: ${(props) => props.theme.spacing(6)}px;

    ${(props) => props.theme.breakpoints.up("lg")} {
        padding: ${(props) => props.theme.spacing(10)}px;
        width: 20%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 black;
`;

const BigAvatar = styled(Avatar)`
    width: 92px;
    height: 92px;
    text-align: center;
    margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles({
    link: {
        margin: 5,
        textDecoration: "none",
    },
    linksWrapper: {
        display: "flex",
        marginTop: "auto",
    },
    myAlert: {
        marginTop: 15,
        width: "90%",
    },
    hide: {
        display: "none",
    },
    btn: {
        marginTop: 20,
    },
    forgotText: {
        marginTop: 10,
    },
    hidePassBtn: {
        width: 16,
        height: 16,
        margin: -8,
    },
    wrapper: {
        position: "absolute",
        top: "40%",
        transform: "translateY(-40%)",
    },
});

const SignInForm = () => {
    const classes = useStyles();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { handleSignIn } = useContext(AuthContext);


    const handleSubmit = (e) => {
        e.preventDefault(); 
        handleSignIn(username, password);

    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    return (
        <Root>
            <Wrapper>
                <div className={classes.wrapper}>
                    <Helmet title="Sign In" />
                    <BigAvatar
                        variant="square"
                        alt="Login logo"
                        src="/img/login_logo.svg"
                    />

                    <Typography component="h2" variant="body1" align="center">
                        Log in to access droov.io <br></br>management panel
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">
                                Email Address
                            </InputLabel>
                            <Input
                                id="email"
                                name="email"
                                value={username}
                                autoComplete="email"
                                autoFocus
                                onChange={handleUsernameChange}
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                id="password"
                                autoComplete="current-password"
                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {showPassword ? (
                                                <Visibility
                                                    className={
                                                        classes.hidePassBtn
                                                    }
                                                />
                                            ) : (
                                                <VisibilityOff
                                                    className={
                                                        classes.hidePassBtn
                                                    }
                                                />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            mb={2}
                            id="submit"
                            type="submit"
                            value="Submit"
                            className={classes.btn}
                        >
                            Sign in
                        </Button>
                        <Typography
                            variant="body1"
                            component="h2"
                            align="center"
                            className={classes.forgotText}
                        >
                            Forgot password?{" "}
                            <Link
                                href="https://www.droov.io/contact-us/"
                                style={{ textDecoration: "none" }}
                            >
                                Contact Us
                            </Link>
                        </Typography>
                    </form>
                    <Alert
                        mb={4}
                        severity="error"
                        className={`${classes.myAlert} ${
                            !showAlert ? classes.hide : ""
                        }`}
                    >
                        Wrong email or password
                    </Alert>
                </div>

                <div className={classes.linksWrapper}>
                    <Typography
                        className={classes.link}
                        variant="body1"
                        align="center"
                        color="primary"
                    >
                        <Link
                            href="https://reinio.de/terms-and-conditions/"
                            style={{ textDecoration: "none" }}
                        >
                            Terms of Use
                        </Link>
                    </Typography>
                    <Typography
                        className={classes.link}
                        variant="body1"
                        align="center"
                        color="primary"
                    >
                        <Link
                            href="https://www.droov.io/privacy-policy/"
                            style={{ textDecoration: "none" }}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                </div>
            </Wrapper>
        </Root>
    );
};
export default SignInForm;
