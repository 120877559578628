export const TEST_ACTION = "TEST_ACTION";

// Actions for Logistics View
export const FETCH_LOGISTICS_TASK_LIST = "FETCH_LOGISTICS_TASK_LIST";
export const OPEN_EXPORT_LOGISTICS_TASK_FORM =
    "OPEN_EXPORT_LOGISTICS_TASK_FORM";

// Actions for Courier View
export const FETCH_COURIERS = "FETCH_COURIERS";
export const ADD_COURIER = "ADD_COURIER";
export const EDIT_COURIER = "EDIT_COURIER";
export const DELETE_COURIER = "DELETE_COURIER";

// Actions for Customer View
export const CURRENT_CUSTOMER = "CURRENT_CUSTOMER";
export const NEW_CUSTOMER_FORM = "NEW_CUSTOMER_FORM";
export const EDIT_CUSTOMER_FORM = "EDIT_CUSTOMER_FORM";
export const FETCH_CUSTOMER_LIST = "FETCH_CUSTOMER_LIST";
export const RELOAD_CUSTOMER_LIST = "RELOAD_CUSTOMER_LIST";

// Actions for Main Drawer
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

// Actions for Category View
export const NEW_CATEGORY_FORM = "NEW_CATEGORY_FORM";
export const EDIT_CATEGORY_FORM = "EDIT_CATEGORY_FORM";
export const CURRENT_CATEGORY = "CURRENT_CATEGORY";
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const HIDE_CATEGORY = "HIDE_CATEGORY";
export const HIDE_CATEGORY_ITEM = "HIDE_CATEGORY_ITEM";
export const DELETE_CATEGORY_FORM = "DELETE_CATEGORY_FORM";
export const DELETE_CATEGORY_ACTION_FORM = "DELETE_CATEGORY_ACTION_FORM";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";

// Actions for Category Items
export const NEW_CATEGORY_ITEM_FORM = "NEW_CATEGORY_ITEM_FORM";
export const EDIT_CATEGORY_ITEM_FORM = "EDIT_CATEGORY_ITEM_FORM";
export const DELETE_CATEGORY_ITEM_FORM = "DELETE_CATEGORY_ITEM_FORM";
export const ADD_NEW_CATEGORY_ITEM = "ADD_NEW_CATEGORY_ITEM";
export const DELETE_CATEGORY_ITEM = "DELETE_CATEGORY_ITEM";
export const EDIT_CATEGORY_ITEM = "EDIT_CATEGORY_ITEM";

// Logistics Action types
export const SHOW_DEFAULT_SCHEDULE = "SHOW_DEFAULT_SCHEDULE";
export const SHOW_CUSTOM_SCHEDULE = "SHOW_CUSTOM_SCHEDULE";
export const FETCH_REGIONS = "FETCH_REGIONS";
export const SHOW_REGION_DETAILS = "SHOW_REGION_DETAILS";
export const SET_REGION_DISABLED = "SET_REGION_DISABLED";
export const ADD_REGION = "ADD_REGION";
export const EDIT_REGION = "EDIT_REGION";
export const GET_REGION = "GET_REGION";
export const DELETE_REGION = "DELETE_REGION";
export const ADD_ZIP_CODE = "ADD_ZIP_CODE";
export const EDIT_ZIP_CODE = "EDIT_ZIP_CODE";
export const DELETE_ZIP_CODE = "DELETE_ZIP_CODE";
export const GET_ZIP_CODE = "GET_ZIP_CODE";
export const GET_WEEKS = "GET_WEEKS";
export const SET_WEEK = "SET_WEEK";
export const ADD_TIMELINE = "ADD_TIMELINE";
export const DELETE_TIMELINE = "DELETE_TIMELINE";
export const EDIT_TIMELINE = "EDIT_TIMELINE";
export const GET_TIMELINE = "GET_TIMELINE";
export const SET_DAY_TO_EDIT = "SET_DAY_TO_EDIT";
export const GET_DEFAULT_TIMELINE = "GET_DEFAULT_TIMELINE"
export const DELETE_DEFAULT_TIMELINE = "DELETE_DEFAULT_TIMELINE"
export const ADD_DEFAULT_TIMELINE = "ADD_DEFAULT_TIMELINE"
export const EDIT_DEFAULT_TIMELINE = "EDIT_DEFAULT_TIMELINE"

// Actions for Order View
export const FETCH_ORDERS = "FETCH_ORDERS";
export const CURRENT_ORDER = "CURRENT_ORDER";
export const TOGGLE_ADD_ORDER_FORM = "TOGGLE_ADD_ORDER_FORM";
export const RELOAD_ORDER_LIST = "RELOAD_ORDER_LIST";
