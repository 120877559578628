import React from "react";
import { connect } from "react-redux";

import Helmet from "react-helmet";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";

import maTheme from "util/theme";
import Routes from "util/routes/Routes";

function App() {
    return (
        <React.Fragment>
            <Helmet
                titleTemplate="%s - droov.io"
                defaultTitle="droov.io - Administration panel"
            />
            <StylesProvider injectFirst>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiThemeProvider theme={maTheme[0]}>
                        <ThemeProvider theme={maTheme[0]}>
                            <Router>
                                <Routes />
                            </Router>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </React.Fragment>
    );
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
