import { combineReducers } from "redux";

import customerReducer from "./customerReducer";
import categoryReducer from "./categoryReducer";
import denseTableReducer from "./denseTableReducer";
import logisticsReducer from "./logisticsReducer";
import testReducer from "./testReducer";
import CompactInfoCardReducer from "./CompactInfoCardReducer";

import drawerReducer from "./drawerReducer";
import couriersReducer from "./couriersReducer";
import orderReducer from "./orderReducer";

export default combineReducers({
    customer: customerReducer,
    category: categoryReducer,
    denseTable: denseTableReducer,
    test: testReducer,
    compact_info_card: CompactInfoCardReducer,
    drawerReducer,
    couriers: couriersReducer,
    logistics: logisticsReducer,
    order: orderReducer,
});
