import * as types from "../types";

const initialState = {
    data: [
      { date: "13-12-2019", device: "Android", title: "Thank you for ordering", description: "Please leave a feedback", notes: "oid:asfdasfasf" },
      { date: "11-12-2019", device: "iOS", title: "Thank you for ordering", description: "Please leave a feedback", notes: "oid:asfdasfasf" },
    ]
}

export default function table1Reducer(state = initialState, actions) {
    switch (actions.type) {
        case types.TEST_ACTION:
            return {
                ...state,
                testProperty: actions.payload,
            };

        default:
            return state;
    }
}
