import React from "react";

import async from "components/Async";

import {
    ShoppingCart,
    MessageCircle,
    Map,
    MapPin,
    Truck,
    Users,
    ShoppingBag,
    Percent,
} from "react-feather";

// Pages components
//const Dashboard = async(() => import("pages/Dashboard"));
const Delivery = async(() => import("pages/Delivery"));
const TourPlanner = async(() => import("pages/TourPlanner"));
const Logistics = async(() => import("pages/Logistics"));
const LogisticsTasks = async(() => import("pages/LogisticsTasks"));
const Couriers = async(() => import("pages/Couriers"));
const Orders = async(() => import("pages/Orders"));
const Customers = async(() => import("pages/Customers"));
const OrderDetails = async(() => import("pages/OrderDetails"));
const CustomerDetails = async(() => import("pages/CustomerDetails"));
const Catalogue = async(() => import("pages/Catalogue"));
const Discounts = async(() => import("pages/Discounts"));
const Support = async(() => import("pages/Support"));
const CreateOrder = async (() => import("pages/CreateOrder"));

const deliveryRoutes = {
    id: "Delivery",
    path: "/",
    header: "Management",
    icon: <MapPin />,
    containsHome: true,
    component: Delivery,
};

/* subsection of Logistics
const logisticsTasksRoutes = {
    id: "LogisticsTasks",
    path: "/logisticstasks",
    containsHome: true,
    component: LogisticsTasks,
    name: "Tasks",
};

// subsection of Logistics 
const logisticsMainRoutes = {
    id: "LogisticsMain",
    path: "/logistics",
    containsHome: true,
    component: Logistics,
    name: "Planner",
};

// subsection of Logistics 
const logisticsTourPlanner = {
  id: "TourPlanner",
  path: "/tourplanner",
  containsHome: true,
  component: TourPlanner,
  name: "Tours",
};
*/

const logisticsRoutes = {
    id: "Logistics",
    path: "/tourplanner",
    icon: <Map />,
    containsHome: true,
    component: TourPlanner,
};

const couriersRoutes = {
    id: "Couriers",
    path: "/couriers",
    icon: <Truck />,
    containsHome: true,
    component: Couriers,
};

const ordersRoutes = {
    id: "Orders",
    path: "/orders",
    icon: <ShoppingCart />,
    containsHome: true,
    component: Orders,
};

const customersRoutes = {
    id: "Customers",
    path: "/customers",
    icon: <Users />,
    containsHome: true,
    component: Customers,
};

/*
const hubRoutes = {
    id: "Hubs",
    path: "/hub",
    icon: <Package />,
    containsHome: true,
    component: Hub,
};
*/

const catalogueRoutes = {
    id: "Catalogue",
    path: "/catalogue",
    icon: <ShoppingBag />,
    containsHome: true,
    component: Catalogue,
};

const discountsRoutes = {
    id: "Discounts",
    path: "/discounts",
    icon: <Percent />,
    containsHome: true,
    component: Discounts,
};

/*
const faqRoutes = {
    id: "F.A.Q",
    path: "/faq",
    header: "Support",
    icon: <HelpCircle />,
    containsHome: true,
    component: FAQ,
};
*/

const customerDetailsRoutes = {
    id: "CustomerDetails",
    path: "/customers/:customerId",
    icon: <ShoppingCart/>,
    containsHome: false,
    component: CustomerDetails,
};
const orderDetailsRoutes = {
    id: "OrderDetails",
    path: "/orders/:orderId",
    icon: <ShoppingCart/>,
    containsHome: false,
    component: OrderDetails,
};

// By Tejas
const createOrderRoute = {
    /*id: "CreateOrder",
    path: "/createorder",
    containsHome: false,
    component: CreateOrder,*/

    id: "CreateOrder",
    path: "/createorder",
    header: "Support",
    icon: <MessageCircle />,
    containsHome: true,
    component: CreateOrder,
};


const supportRoutes = {
    id: "Support",
    path: "/support",
    header: "Support",
    icon: <MessageCircle />,
    containsHome: true,
    component: Support,
};

// Routes using the Dashboard layout
export const layoutRoutes = [
    //dashboardsRoutes,
    deliveryRoutes,
    logisticsRoutes,
    couriersRoutes,
    ordersRoutes,
    customersRoutes,
    orderDetailsRoutes,
    customerDetailsRoutes,
    createOrderRoute,
    ///hubRoutes,
    catalogueRoutes,
    discountsRoutes,
    //faqRoutes,
    supportRoutes,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
    //dashboardsRoutes,
    deliveryRoutes,
    logisticsRoutes,
    couriersRoutes,
    ordersRoutes,
    customersRoutes,
    //hubRoutes,
    catalogueRoutes,
    discountsRoutes,
    //faqRoutes,
    supportRoutes,
];
