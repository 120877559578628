import {
    NEW_CATEGORY_FORM,
    EDIT_CATEGORY_FORM,
    CURRENT_CATEGORY,
    ADD_NEW_CATEGORY,
    EDIT_CATEGORY,
    DELETE_CATEGORY,
    HIDE_CATEGORY,
    NEW_CATEGORY_ITEM_FORM,
    EDIT_CATEGORY_ITEM_FORM,
    ADD_NEW_CATEGORY_ITEM,
    DELETE_CATEGORY_ITEM,
    DELETE_CATEGORY_FORM,
    DELETE_CATEGORY_ITEM_FORM,
    DELETE_CATEGORY_ACTION_FORM,
    HIDE_CATEGORY_ITEM,
    EDIT_CATEGORY_ITEM,
    UPDATE_CATEGORIES,
} from "../types";

export const categories = [
    {
        id: 0,
        title: "Shirts and Blouses",
        description: "Description for Shirts and Blouses",
        disabled: false,
        items: [
            {
                id: 0,
                displayName: "Shirts and Blouses item 1",
                description: "Description Shirts and Blouses item 1",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: false,
            },
            {
                id: 1,
                displayName: "Shirts and Blouses item 2",
                description: "Description Shirts and Blouses item 2",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: true,
            },
        ],
    },
    {
        id: 1,
        title: "Trousers",
        description: "Description for Trousers",
        disabled: false,
        items: [
            {
                id: 0,
                displayName: "Trousers item 1",
                description: "Description Trousers item 1",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: false,
            },
            {
                id: 1,
                displayName: "Trousers item 2",
                description: "Description Trousers item 2",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: false,
            },
        ],
    },
    {
        id: 2,
        title: "Suits",
        description: "Description for Suits",
        disabled: true,
        items: [
            {
                id: 0,
                displayName: "Suits item 1",
                description: "Description Suits item 1",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: false,
            },
            {
                id: 1,
                displayName: "Suits item 2",
                description: "Description Suits item 2",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: false,
            },
        ],
    },
    {
        id: 3,
        title: "Home",
        description: "Description for Home",
        disabled: false,
        items: [
            {
                id: 0,
                displayName: "Home item 1",
                description: "Description Home item 1",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: false,
            },
            {
                id: 1,
                displayName: "Home item 2",
                description: "Description Home item 2",
                price: "7",
                specialPrice: "5.50",
                type: "per item",
                turnaround: "2",
                disabled: false,
            },
        ],
    },
];
const initialState = {
    openNewCategoryForm: false,
    openNewCategoryItemForm: false,
    openEditCategoryForm: false,
    openEditCategoryItemForm: { open: false, itemId: 0 },
    openDeleteCategoryForm: false,
    openDeleteCategoryActionForm: false,
    openDeleteCategoryItemForm: { open: false, itemId: 0 },
    curCategory: categories[0],
    data: categories,
};

export default function categoryReducer(state = initialState, action) {
    const curCategoryIndex = state.curCategory.id;
    const categories = [...state.data];

    switch (action.type) {
        case CURRENT_CATEGORY:
            return {
                ...state,
                curCategory: action.payload,
            };
        case ADD_NEW_CATEGORY:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case UPDATE_CATEGORIES:
            return {
                ...state,
                data: action.payload,
            };
        case EDIT_CATEGORY:
            const { id, newCategory } = action.payload;

            const newDataEdit = state.data.map((category) => {
                if (category.id === id) {
                    return newCategory;
                }
                return category;
            });
            return {
                ...state,
                data: newDataEdit,
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                data: state.data.filter(
                    (category) => category.id !== action.payload.id
                ),
                curCategory: state.data.find(
                    (cat) => cat.id !== action.payload.id
                ),
            };
        case HIDE_CATEGORY:
            const newDataHideCategory = state.data.map((category) => {
                if (category.id === action.payload) {
                    return { ...category, disabled: !category.disabled };
                }
                return category;
            });

            return {
                ...state,
                data: newDataHideCategory,
                curCategory: newDataHideCategory.find(
                    (cat) => cat.id === curCategoryIndex
                ),
            };
        case EDIT_CATEGORY_FORM:
            return {
                ...state,
                openEditCategoryForm: action.payload,
            };
        case EDIT_CATEGORY_ITEM_FORM:
            return {
                ...state,
                openEditCategoryItemForm: action.payload,
            };
        case DELETE_CATEGORY_FORM:
            return {
                ...state,
                openDeleteCategoryForm: action.payload,
            };
        case DELETE_CATEGORY_ACTION_FORM:
            return {
                ...state,
                openDeleteCategoryActionForm: action.payload,
            };
        case DELETE_CATEGORY_ITEM_FORM:
            return {
                ...state,
                openDeleteCategoryItemForm: action.payload,
            };
        case NEW_CATEGORY_FORM:
            return {
                ...state,
                openNewCategoryForm: action.payload,
            };
        case ADD_NEW_CATEGORY_ITEM:
            const newData = categories.map((category) => {
                if (category.id === action.payload.currentCategory.id) {
                    return {
                        ...category,
                        items: [...category.items, action.payload.categoryItem],
                    };
                }
                return category;
            });

            return {
                ...state,
                data: newData,
                curCategory: newData.find((cat) => cat.id === curCategoryIndex),
            };

        case DELETE_CATEGORY_ITEM:
            const newDataDelete = categories.map((category) => {
                if (category.id === curCategoryIndex) {
                    return {
                        ...category,
                        items: [
                            ...category.items.filter(
                                (categoryItem) =>
                                    categoryItem.id !==
                                    action.payload.categoryItemId
                            ),
                        ],
                    };
                }
                return category;
            });

            return {
                ...state,
                data: newDataDelete,
                curCategory: newDataDelete.find(
                    (cat) => cat.id === curCategoryIndex
                ),
            };

        case HIDE_CATEGORY_ITEM:
            const newDataHideItem = categories.map((category) => {
                if (category.id === curCategoryIndex) {
                    return {
                        ...category,
                        items: [
                            ...category.items.map((categoryItem) => {
                                if (categoryItem.id === action.payload) {
                                    return {
                                        ...categoryItem,
                                        disabled: !categoryItem.disabled,
                                    };
                                }
                                return categoryItem;
                            }),
                        ],
                    };
                }
                return category;
            });
            return {
                ...state,
                data: newDataHideItem,
                curCategory: newDataHideItem.find(
                    (cat) => cat.id === curCategoryIndex
                ),
            };

        case EDIT_CATEGORY_ITEM:
            const newDataEditItem = categories.map((category) => {
                if (category.id === curCategoryIndex) {
                    return {
                        ...category,
                        items: [
                            ...category.items.map((categoryItem) => {
                                return categoryItem.id === action.payload.id
                                    ? {
                                          ...action.payload,
                                      }
                                    : categoryItem;
                            }),
                        ],
                    };
                }
                return category;
            });

            return {
                ...state,
                data: newDataEditItem,
                curCategory: newDataEditItem.find(
                    (cat) => cat.id === curCategoryIndex
                ),
            };
        case NEW_CATEGORY_ITEM_FORM:
            return {
                ...state,
                openNewCategoryItemForm: action.payload,
            };
        default:
            return state;
    }
}
