import React, { useState, createContext } from "react"

export const TourContext = createContext();

export const TourProivder = props => {
  const [tours, setTours] = useState([]);
  const [currTour, setCurrTour] = useState({})
  const [couriers, setCouriers] = useState([]);
  const [regions, setRegions] = useState([]);


  const getCurrentRegions = () => {
    return regions.filter(region => region.tourRef === currTour.id).sort(function(a, b) {
      return parseInt(a.timeFrame.substr(0,2)) - parseInt(b.timeFrame.substr(0,2));
    })
  }

  return (
    <TourContext.Provider value={[tours, setTours, currTour, setCurrTour, couriers, setCouriers, regions, setRegions, getCurrentRegions]}>
      {props.children}
    </TourContext.Provider>
  );
}